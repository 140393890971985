
jQuery(document).ready(function($){
  
    $('#mapSelectionDetails').hide();
    $('div.arrow-up').hide();
});

function selectRegion(element){

  var parentElement = jQuery(element).closest(".dropdown");

  //remove active class from all elements in list and after, mark this element as active
  jQuery(parentElement).find("ul .active").removeClass("active");
  jQuery(element).parent().addClass("active");

  //replace button text
  jQuery(parentElement).find(".dropdowButtonTxt").html(jQuery(element).html());

  var option = jQuery(element);
  var imgSrc = jQuery(element).data('img-src');
  var description = jQuery(element).data('description');
  
  if( description != 'default' ){
    jQuery('#mapSelectionDetails').show();
    jQuery('div.arrow-up').show();
  } else {
    jQuery('#mapSelectionDetails').hide();
    jQuery('div.arrow-up').hide();
  }
  
  //alert("imgSrc = " + imgSrc + " ----- " + description);
  jQuery('#mapSelectionDetails').html(description);
  jQuery('#mapImg').attr("src", imgSrc);
}
