
jQuery(document).ready(function($){
    
    $('#detailsModal').on('show.bs.modal', function (event) {
       // alert("test");
        var button = $(event.relatedTarget); // Button that triggered the modal
        var contentTitle = button.data('content-title'); // Extract info from data-* attributes
        var contentDetails = button.data('content-details');
        var name = button.data('name');
        var position = button.data('position');
        var teamLocation = button.data('location');
        var imgSrc = button.data('img-src');

        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this);
        modal.find('.name').text(name);
        modal.find('.position').text(position);
        modal.find('.teamLocation').text(teamLocation);
        modal.find('.content-title').text(contentTitle);
        modal.find('.content-details').html(contentDetails);
        modal.find('.profile-img').attr("src", imgSrc);
    })
});
