
jQuery(document).ready(function($){
    
  $('iframe').attr('width','100%');
  
  $('.additionalInfo').on('click', function(){
    $(this).find('img').toggleClass('active');
  });
  
  $(window).scroll(function(){
    if($(window).scrollTop() > 1200){
      $('.backToTopButton').fadeIn();
    } else {
      $('.backToTopButton').fadeOut();
    }
  });
  
  $('.backToTopButton').on('click', function(){
    $('html, body').animate({
      scrollTop: 0
    }, 900);
  });
  
  
  // Contact Form 7 mail sent
  $(".wpcf7").on('wpcf7:mailsent', function(event){
    var notification = '<p>Thank You for applying, what happens next?</p>';
    notification += '<p>After internal scrutiny (approximately one week) we will acknowledge your application and confirm your subscription. The application is then considered by BESA\'s Executive '; 
    notification += 'Council. Successful applicants are welcomed into membership approximately one month later. A contract will exist with BESA as soon as our Welcome Pack is sent to you.';
    notification += '<p>If you have any questions, please contact Member Services Manager, Mark Rosser at <a mailto:mark@besa.org.uk>mark@besa.org.uk</a>'; 

    $('.joinFormPage .container').append('<div id="cf7Notification"></div>');
    $('#cf7Notification').addClass('contactForm7Notification').hide();
    $('#cf7Notification').addClass('contactForm7Notification');
    $('#cf7Notification').append(notification);
    $('#cf7Notification').fadeIn();
  });
  
  $('#gmapModal').on('shown.bs.modal', function (event) {
    google.maps.event.trigger(map, "resize");
    map.setCenter({lat: lat, lng: long});
  });
  
  
  // swap out the strong tags with h4 tags in the moreinfo content dropdown (used on the special interest groups page etc)
  $('.additionalInfo + .content p > strong').wrap('<h4></h4>');
  $('.additionalInfo + .content p').css({'color':'#9b9b9b','margin':'30px 0'});
  
  
  var faqLinks = $('.faq__link--nav');
  
  faqLinks.each(function()
  {
    var target = $(this).data('name');
    
    $(this).on('click', function()
    {
      console.log("This is pointing to: " + target);
      $('body, html').animate({
          'scrollTop':   $('[name="'+target+'"]').offset().top
      }, 1300);
    });
  }); 
  
  
  $('#sip_pic_upload_file').hide();
  // user clicks the upload button for the profile image on the member area
  $('#sip_pic_choose_btn').on('click', function(){
    $('#sip_pic_upload_file').click();
  });
  
  $('#sip_pic_upload_file').on('change', function(){
    $('#sip_member_form').submit();
  });
  
  
  
  
  if( jQuery('#modal_edit_post').data('editmode') == true )
  {
    console.log(jQuery('#modal_edit_post'));
    jQuery('#modal_edit_post').modal('show');
  }
  
  // // send form to update profile photo
//   $('#sip_pic_upload_btn').on('click', function(){
//     $('#sip_member_form').submit();
//   });
});